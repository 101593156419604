<template>
  <div class="side-menu next mov-fix">
    <div class="brand-logo text-center">
      <img src="@/assets/images/defaultlogo.jpg" alt="" class="img-fluid">
    </div>
    <ul class="custom-inline-box row">
      <li class="list-group-item list-group-item-action col-md-6" v-if="plot_details.value">
        <span class="title">Plot Number</span>
        <span class="info">{{plot_details.value}}</span>
      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="plot_details.area">
        <span class="title">Area sq.ft</span>
        <span class="info">{{plot_details.area}} sq.ft</span>
      </li>
      <li class="list-group-item list-group-item-action col-md-6" v-if="plot_details.all_in_price">
        <span class="title">Total Price</span>
        <span class="info">{{plot_details.all_in_price}}</span>
      </li>
      <li class="list-group-item col-md-12">
        <p class="small">*Price includes GST, Stamp Duty, Registration charges. It does not include Maintenance.</p>
      </li>
    </ul>
    <div class="form-wrapper">
      <div class="form-header">
        <h3>{{plot_details.value}}</h3>
      </div>
      <div class="form-body">
        <form @submit.prevent="goToPlans">
          <div class="form-group">
            <label for="">Full Name</label>
            <input type="text" class="form-control" required v-model="lead.name" placeholder="Enter Full Name">
          </div>
          <div class="form-group">
            <label for="">Mobile No</label>
            <input type="number" class="form-control" required v-model="lead.phone" placeholder="Enter Number">
          </div>
          <div class="form-group">
            <label for="">Email Address</label>
            <input type="email" class="form-control" v-model="lead.email" placeholder="Enter Email">
          </div>
          <div class="form-group">
            <div class="text-right mt-5 ">
              <button type="button" class="btn btn-primary prevButton" @click="$router.replace('/')">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                  <g>
                    <g>
                      <path d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160
                        c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125
                        c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z
                        " />
                    </g>
                  </g>
                </svg>
              </button>
              <span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="Please Select a Floor Range">
                <button type="submit" class="btn btn-primary ">Proceed</button>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    masterJson
  } from '@/assets/scripts/utility.js'
  export default {
    data() {
      return {
        plot_details: {},
        lead: {
          name: null,
          phone: null,
          email: null,
        }
      }
    },
    created() {
      if (this.$route.params.plot) {
        this.plot_details = Object.assign({}, ...this.masterBuildings.filter(k => k.key.includes(this.$route.params.plot)));
      }
    },
    methods: {
      goToPlans() {
        this.$axios
        .post("public_api/leads", this.lead )
        .then(res => {
           localStorage.setItem('crmleadNo', res.data.data)
           this.$router.replace({ name: "Book", params: {plot: this.plot_details.key} })
        })
        .catch(err => {
          if (err.response.status === 500) {
            swal({
              title: "Server Error!",
              icon: "error"
            });
          } else {
            swal({
              title: "Error",
              text: err.response.data.message,
              icon: "error"
            });
          }
        });
      }
    },
    computed: {
      masterBuildings(){
        return masterJson.Buildings;
      },
    }
  }
</script>
<style scoped>
  .side-menu {
    background: transparent;
    box-shadow: none;
  }

  .brand-logo.text-center {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .custom-inline-box {
    background: #fff;
    padding: 0;
    margin: 0;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .list-group-item {
    border: none;
  }

  .list-group-item .title {
    font-weight: 400;
    font-size: 12px;
    color: #222222;
    opacity: 0.5;
    display: block;
  }

  .list-group-item .info {
    font-weight: 500;
    font-size: 15px;
    color: #222222;
  }

  .list-group-item:last-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .list-group-item .small {
    color: #222222;
    font-weight: 400;
    font-size: 10px;
    opacity: 0.5;
  }

  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: #fff;
  }

  .image-gallery {
    background: #fff;
    box-shadow: 1px 2px 50px rgba(0, 0, 0, 0.01);
    border-radius: 8px 8px 0px 0px;
  }

  .image-gallery h3 {
    font-weight: 600;
    font-size: 15px;
    color: #222222;
    padding: 20px;
    margin-bottom: 0;
  }

  .form-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .form-header {
    background: linear-gradient(180deg, #6187F7 0%, #4470F5 100%);
    box-shadow: 1px 2px 50px rgba(0, 0, 0, 0.01);
    border-radius: 8px 8px 0px 0px;
    padding: 20px;
    color: #fff;
  }

  .form-header h3 {
    font-weight: 600;
    font-size: 15px;
    color: #FFFFFF;
    margin: 0;
  }

  .form-body {
    padding: 20px;
  }

  .form-body label {
    color: #222222;
    font-weight: 400;
    font-size: 11px;
    opacity: 0.5;
  }

  .form-body .form-control {
    background: rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-height: 48px;
  }
</style>