<template>
    <div class="">
      <div class="page-header">
        <a
          @click="$router.replace('/')"
          class="prevButton backArrow mr-2"
          data-dismiss="modal"
        >
          <svg width ="20"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160
          c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125
          c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z
          "
                />
              </g>
            </g>
          </svg>
        </a> Inventory
      </div>
      <div class="p-4">
        <tabs content-class="p-4 shadow flatDetailsHolder">
        <tab  active name="Plot Details" suffix='<span class="arrow-icon">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 490.656 490.656"
                  style="enable-background:new 0 0 490.656 490.656;"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M487.536,120.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,347.581L18.203,120.467c-4.16-4.16-10.923-4.16-15.083,0
                                    c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136l234.667-234.667
                                    C491.696,131.368,491.696,124.605,487.536,120.445z"
                      />
                    </g>
                  </g>
                </svg>
              </span>'>

          <div class="tab-content p-4">
             <div class="row">
             <div class="col-md-12">
               <h3 class="mb-2 mt-2">Plot Details</h3>
             </div>
             <div class="col-md-4" v-if="plot_details.value">
               <span class="title">Plot Number</span>
               <span class="info">{{plot_details.value}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.area">
               <span class="title">Area sq.ft</span>
               <span class="info">{{plot_details.area}} sq.ft</span>
             </div>
             <div class="col-md-4" v-if="plot_details.basic_rate">
               <span class="title">Basic Charge</span>
               <span class="info">{{plot_details.basic_rate}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.development_charge">
               <span class="title">Development Charges</span>
               <span class="info">{{plot_details.development_charge}}</span>
             </div>
             <div class="col-md-4"  v-if="plot_details.agreement_value">
               <span class="title">Agreement Value</span>
               <span class="info">{{plot_details.agreement_value}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.gst">
               <span class="title">GST 18%- development</span>
               <span class="info">{{plot_details.gst}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.stamp_duty">
               <span class="title">Stamp Duty</span>
               <span class="info">{{plot_details.stamp_duty}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.registration">
               <span class="title">Registration</span>
               <span class="info">{{plot_details.registration}}</span>
             </div>
             <div class="col-md-4" v-if="plot_details.all_in_price">
               <span class="title">Total Price</span>
               <span class="info">{{plot_details.all_in_price}}</span>
             </div>

             <div class="col-md-12">
               <h3 class="mb-2 mt-2">Amenities</h3>
             </div>
             <ul class="row amenities-list">
                     <li class="col-md-4">Street Lighting</li>
                     <li class="col-md-4">24*7 Security</li>
                     <li class="col-md-4">Yoga and Meditation Zone</li>
                     <li class="col-md-4">Kids Rock Climbing</li>
                     <li class="col-md-4">Gazebo</li>
                     <li class="col-md-4">Common Space Zone</li>
                     <li class="col-md-4">Garden</li>
                     <li class="col-md-4">Kids Play Area</li>
                     <li class="col-md-4">Roadside Plantation</li>
                     <li class="col-md-4">Kite Flying Zone</li>
                     <li class="col-md-4">Green Zone</li>
                     <li class="col-md-4">Walking track</li>
                     <li class="col-md-4">Light Connection for each plot</li>
                     <li class="col-md-4">Club House</li>
                     <li class="col-md-4">Water Connection for Each Plot</li>
                     <li class="col-md-4">Gymnasium</li>
                     <li class="col-md-4">Internal Roads – 9m and 12m wide</li>
                     <li class="col-md-4">Entrance Gate</li>
                     <li class="col-md-4">Demarcated NA Plots with Individual 7/12</li>
                   </ul>
           </div>
          </div>
        </tab>
        <tab name="Payment details" suffix='<span class="arrow-icon">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 490.656 490.656"
                  style="enable-background:new 0 0 490.656 490.656;"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M487.536,120.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,347.581L18.203,120.467c-4.16-4.16-10.923-4.16-15.083,0
                                    c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136l234.667-234.667
                                    C491.696,131.368,491.696,124.605,487.536,120.445z"
                      />
                    </g>
                  </g>
                </svg>
              </span>'>


          <div class="tab-content  p-4">
            <h3 class="mb-2 mt-2">Price Breakup</h3>
            <table class="table table-bordered table-hover ctTableSize">
              <thead>
                <tr>
                  <th style="width:70%">Description</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="plot_details.basic_rate">
                  <td>Basic Charge</td>
                  <td>
                    <i class="fa fa-inr"></i> {{plot_details.basic_rate}}
                  </td>
                </tr>
                <tr v-if="plot_details.development_charge">
                  <td>Development Charges</td>
                  <td>
                    <i class="fa fa-inr"></i> {{plot_details.development_charge}}
                  </td>
                </tr>
                <tr v-if="plot_details.agreement_value">
                  <td>Agreement Value</td>
                  <td>
                    <i class="fa fa-inr"></i>  {{plot_details.agreement_value}}
                  </td>
                </tr>
                <tr  v-if="plot_details.gst">
                  <td>GST 18%- development</td>
                  <td>
                    <i class="fa fa-inr"></i> {{plot_details.gst}}
                  </td>
                </tr>
                <tr></tr>
                <tr v-if="plot_details.stamp_duty">
                  <td>Stamp Duty</td>
                  <td>
                    <i class="fa fa-inr"></i> {{plot_details.stamp_duty}}
                  </td>
                </tr>
                <tr  v-if="plot_details.registration">
                  <td>Registration</td>
                  <td>
                    <i class="fa fa-inr"></i> {{plot_details.registration}}
                  </td>
                </tr>

                <tr v-if="plot_details.all_in_price">
                  <td>
                    <strong>Total Price</strong>
                  </td>
                  <td>
                    <i class="fa fa-inr"></i> &nbsp;
                    <strong>{{plot_details.all_in_price}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </tab>
        <tab name="Book Now" suffix='<span class="arrow-icon">
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 490.656 490.656"
                  style="enable-background:new 0 0 490.656 490.656;"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M487.536,120.445c-4.16-4.16-10.923-4.16-15.083,0L245.339,347.581L18.203,120.467c-4.16-4.16-10.923-4.16-15.083,0
                                    c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.136l234.667-234.667
                                    C491.696,131.368,491.696,124.605,487.536,120.445z"
                      />
                    </g>
                  </g>
                </svg>
              </span>'>


          <div class="row justify-content-center tab-content">
              <div class="col-md-7">
                <h3
                  class="text-center mt-4 mb-2"
                >Booking Summary</h3>

                <div class="payment-grid">
                   <div class="small mb-2">Your selection</div>
                  <div class="list-group">
                    <div class="list-group-item list-group-item-action active">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Plot: {{plot_details.value}}</h5>
                      </div>
                    </div>
                    <div class="list-group-item list-group-item-action" v-if="plot_details.area">
                      <div class="d-flex w-100 justify-content-between">
                        <small class="text-muted d-block ">Area sq.ft</small>

                        <small class="icon-tick">
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512;"
                            xml:space="preserve"
                            width="14px"
                            height="14pt"
                          >
                            <g>
                              <g>
                                <path
                                  d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256
			s26.629,132.667,74.98,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.98
			C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M378.306,195.073L235.241,338.139
			c-2.929,2.929-6.768,4.393-10.606,4.393c-3.839,0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354,0-21.213
			c5.857-5.858,15.355-5.858,21.213,0l69.728,69.727l132.458-132.46c5.857-5.858,15.355-5.858,21.213,0
			C384.164,179.718,384.164,189.215,378.306,195.073z"
                                />
                              </g>
                            </g>
                          </svg>
                        </small>
                      </div>
                      <p class="mb-1 d-block info">{{plot_details.area}} sq.ft</p>
                    </div>
                    <div class="list-group-item list-group-item-action" v-if="plot_details.all_in_price">
                      <div class="d-flex w-100 justify-content-between">
                        <small class="text-muted d-block">Total Price</small>

                        <small class="icon-tick">
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style="enable-background:new 0 0 512 512;"
                            xml:space="preserve"
                            width="14px"
                            height="14pt"
                          >
                            <g>
                              <g>
                                <path
                                  d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.332,26.629,74.98,74.98C26.629,123.332,0,187.62,0,256
			s26.629,132.667,74.98,181.019C123.332,485.371,187.62,512,256,512s132.667-26.629,181.019-74.98
			C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M378.306,195.073L235.241,338.139
			c-2.929,2.929-6.768,4.393-10.606,4.393c-3.839,0-7.678-1.464-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354,0-21.213
			c5.857-5.858,15.355-5.858,21.213,0l69.728,69.727l132.458-132.46c5.857-5.858,15.355-5.858,21.213,0
			C384.164,179.718,384.164,189.215,378.306,195.073z"
                                />
                              </g>
                            </g>
                          </svg>
                        </small>
                      </div>
                      <p class="mb-1 d-block info">{{plot_details.all_in_price}}</p>
                    </div>


                  <div class="list-group-item list-group-item-action">
                    <div class="form-group">
                      <small class="text-muted d-block mb-2 ">Price (All incl)</small>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Rs 1,00,000" disabled />
                        <div class="input-group-append">
                          <button class="btn btn-success success-btn" type="button" @click="goBook">Book Now</button>
                        </div>
                      </div>
                    </div>
                  </div>
                     </div>
                </div>
              </div>
            </div>
        </tab>
      </tabs>
      </div>
    </div>
  <!-- </b-modal> -->
</template>
<script>
import { projectName, paymentPlans, santizeBookingParams, bookingUrl } from "@/assets/scripts/utility.js";
import qs from 'qs'
export default {
  props: {
    plot_details: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      selectedPlan: null
    }
  },
  created() {
    let lead_no = localStorage.getItem('crmleadNo')
    if (!lead_no) {
      this.$router.push({name: 'PlotDetail', params: {plot: this.plot_details.key}})
    }
  },
  methods: {
    goBook(){
      let qp = {inventory_id: this.plot_details.key,  typologyName: this.plot_details.value,  leadNo: localStorage.getItem('crmleadNo')}
      let qParams = qs.stringify(santizeBookingParams(qp));
      let finalUrl = `${this.$axios.defaults.baseURL}${bookingUrl}?${qParams}`
      window.location = finalUrl
    }
  },
};
</script>
<style scoped>
.tab-content .title{
  font-weight: 400;
  font-size: 15px;
  display: block;
  color: #212121;
}
.tab-content .info{
  font-weight: 700;
  font-size: 15px;
  display: block;
  color: #212121;
  margin-bottom: 20px;
}
.tab-content  .amenities-list {
  list-style: none;
  padding-left: 15px;

}
.tab-content  .amenities-list li{
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 15px;
  color: #000;
}
p.info{
  font-weight: 500;
  font-size: 15px;
  color: #222222;
}

</style>