<template>
  <div>
    <Details v-if="plot_details" :plot_details ="plot_details"/>
  </div>
</template>

<script>
  import {
    masterJson
  } from '@/assets/scripts/utility.js'
import Details from '@/components/Inventory/Details.vue';
export default {
  components: {
    Details
  },
  data() {
    return {
      plot_details: {},
    }
  },
  created() {
    if (this.$route.params.plot) {
      this.plot_details = Object.assign({}, ...this.masterBuildings.filter(k => k.key.includes(this.$route.params.plot)));
    }
  },
  computed: {
    masterBuildings(){
      return masterJson.Buildings;
    },
  }
}
</script>

<style>

</style>